<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {AgGridVue} from "ag-grid-vue";

export default {
  page: {
    title: "알림톡 포인트 내역",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "발송 내역",
      items: '알림톡 전송 내역을 확인할 수 있습니다.',
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {headerName: '일시', field: 'reg_date'},
        {headerName: '입출내역', field: 'msg'},
        {headerName: '금액', field: 'active_point', valueFormatter: this.currencyFormatter},
        {headerName: '잔액', field: 'total_point', valueFormatter: this.currencyFormatter}
      ],
      historyList: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    AgGridVue,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    //ag-grid methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    currencyFormatter(params) {
      return this.formatNumber(params.value)
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    getHistory() {
      let url = `/api/kakao/history/payment/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.historyList = res.data
      }).catch(err => console.log(err))
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-xl-3">
        <div
            class="alert alert-warning border-0 d-flex align-items-center"
            role="alert"
            style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
        >
          <i
              class="uil uil-exclamation-triangle font-size-16 text-warning me-2"
          ></i>
          <div class="flex-grow-1" style="word-break: keep-all">
            <b>현재 충전하기는 무통장입금만 가능합니다.</b>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5>충전하기</h5>
            <hr>
            <span style="display:inline-block;">예금주: 크리티컬</span><br>
            <h4><img :src="require('@/assets/mcimages/bank_kb.svg')" style="width: 30px;">
              <span style="font-size:24px; line-height:30px;vertical-align:middle;color:#e85f2a ">국민은행<br>0000000000000000</span><br>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <h5>충전 및 사용내역</h5>
            <hr>
            <ag-grid-vue
                style="height: calc(100vh - 340px); width: 100%; text-align: center;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="historyList"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @first-data-rendered="onFirstDataRendered"
                :cacheQuickFilter="true"
                suppressHorizontalScroll="false"
                enableCellTextSelection="true"
            ></ag-grid-vue>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}
</style>
